.Pinyin_HSK {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PH-Back-Btn {
  background: transparent;
  border: none;
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease all;
}

.PH-Back-Btn:hover {
  transform: scale(1.1);
}

.PH-Back-Btn span {
  font-size: 16px;
}

.PH-Body {
  max-width: 1000px;
  width: 100%;
}

.PH-Game-Opt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  justify-content: space-around;
  min-height: calc(100vh - 80px);
  height: auto;
  width: 100%;
  padding: 15px 0;
}

.PH-Card {
  justify-self: center;
  align-self: center;
  padding: 60px 20px;
  border-radius: 5px;
  max-width: 305px;
  color: white;
  font-size: 40px;
  background-color: rgba(100, 148, 237, 0.8);
  box-shadow: inset 0 -3em 3em rgba(220, 20, 60, 0.3),
    0 0 0 2px rgb(255, 255, 255), 0 4px 15px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease all;
  user-select: none;
}

.PH-Card:hover {
  cursor: pointer;
  transform: scale(1.05);
}
