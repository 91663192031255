.CharView{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 999;
}

.CharView-inner{
    background-color: white;
    position: relative;
    height: calc(100vh / 1.5);
    width: calc(100vw / 2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 
        inset 0 -3em 3em rgba(0, 0, 0, 0.1), 
        0 0 0 2px rgb(255, 255, 255),
        0 4px 10px 0 rgba(0, 0, 0, 0.4);
}

.CV-Char{
    font-size: 150px;
}

.CV-Pny{
    font-size: 30px;
    color: crimson;
    margin-top: 20px;
}

.CV-Eng{
    font-size: 60px;
    margin-top: 20px;
    color: blue;
}

.CV-close{
    position: absolute;
    background-color: crimson;
    padding: 3px 8px;
    color: white;
    top: 0;
    right: 0;
    border-radius: 100%;
    margin-top: -20px;
    margin-right: -20px;
}

.CV-close p {
    font-weight: bolder;
    font-size: 18px;
    color: white;
}

.CV-close:hover{
    cursor: pointer;
}