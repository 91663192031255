.Home {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  width: 100%;
  background-color: crimson;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

#Home-header {
  width: 100%;
  text-align: center;
}

.HM-body {
  max-width: 1000px;
  width: 100%;
  margin-bottom: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.HM-Menu {
  min-height: 300px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
}

.Games {
  min-width: 200px;
  background-color: rgba(100, 148, 237, 0.8);
  color: white;
  padding: 70px 40px;
  border-radius: 5px;
  box-shadow: inset 0 -3.5em 3.5em rgba(220, 20, 60, 0.3),
    0 0 0 2px rgb(255, 255, 255), 0 4px 15px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease all;
  user-select: none;
}

.Games:hover {
  cursor: pointer;
  transform: scale(1.06);
}

.Char-details {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0 4px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  user-select: none;
}

.title {
  text-align: left;
  font-weight: bolder;
  color: blue;
}

.count {
  color: blue;
  text-align: right;
  font-weight: bold;
}

.All-Char {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  row-gap: 30px;
  column-gap: 20px;
}

.collapse-Btn {
  padding: 6px 7px;
  margin-left: 10px;
  border: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: inset 0 -4em 4em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255);
  user-select: none;
}

.collapse-Btn:hover {
  cursor: pointer;
}

.HSK-Card {
  min-width: 150px;
  background-color: white;
  padding: 15px 0;
  text-align: center;
  border-radius: 5px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0 4px 10px 0 rgba(0, 0, 0, 0.4);
  transition: 0.3s ease all;
  user-select: none;
}

.HSK-Card:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.Char {
  font-size: 50px;
}

.Pinyin {
  color: crimson;
  font-size: 12px;
}

.Eng {
  color: blue;
  font-size: 20px;
}
